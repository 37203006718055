import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import HeadlineM from "../HeadlineM"

const Access = () => {
  const items = [
    {
      title: "住所",
      desc: "〒378-0015\n群馬県沼田市戸鹿野町３９４−３\n\n沼田駅から約1.1km（車3分、徒歩14分）",
    },
    {
      title: "受付時間",
      desc: "10:00～19:00（月～金）",
    },
    {
      title: "定休日",
      desc: "土曜・日曜・祝日",
    },
  ]
  return (
    <div id="access" className="mt-16">
      <HeadlineM title="アクセス" />
      <div className="flex flex-col-reverse grid-cols-2 gap-8 mt-8 md:grid">
        <div>
          {items.map(item => {
            return (
              <div
                className="flex py-5 border-b border-Yellow"
                key={item.title}
              >
                <div className="w-1/3">{item.title}</div>
                <div className="whitespace-pre-wrap">
                  {item.desc}
                  {item.title === "住所" && (
                    <a
                      href="https://goo.gl/maps/FG9HBazp1tCVKe318"
                      target="_blank"
                      className="block mt-2 text-xs text-blue-500 underline"
                    >
                      Googleマップで開く
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <StaticImage
          src="../../images/classroom.png"
          alt="building"
          className=""
        />
      </div>
    </div>
  )
}

export default Access
