import React from "react"

const Concept = () => {
  return (
    <div className="relative max-w-4xl p-4 m-auto my-24 md:my-32">
      <div className="absolute left-0 text-6xl font-bold -top-4 text-Yellow font-ZenMaruGathic animate-bounce">
        ｢
      </div>
      <p className="text-2xl text-center md:text-3xl">
        コミュニケーションに自信をもって、英語がどんどん楽しくなる。
      </p>
      <p className="mt-5 text-xl text-center font-UbuntuMono">
        Creating joy for English with confidence in communication.
      </p>
      <div className="absolute right-0 text-6xl font-bold -bottom-4 text-Yellow font-ZenMaruGathic animate-bounce">
        ｣
      </div>
    </div>
  )
}

export default Concept
