import React from "react"
import Access from "../components/about/Access"
import Concept from "../components/about/Concept"
import Feature from "../components/about/Feature"
import Map from "../components/about/Map"
import Profile from "../components/about/Profile"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const about = () => {
  return (
    <Layout>
      <Seo title="スクールについて" />
      <Concept />
      <Feature />
      <Profile />
      <Access />
      <div className="mt-16 h-96 md:mt-20">
        <Map />
      </div>
    </Layout>
  )
}

export default about
