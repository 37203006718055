import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

const query = graphql`
  query {
    about1: file(relativePath: { eq: "about/about1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    about2: file(relativePath: { eq: "about/about2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    about3: file(relativePath: { eq: "about/about3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    about4: file(relativePath: { eq: "about/about4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const Feature = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      title: "ネイティブ講師が教える\n実戦で使える英会話",
      desc: "オーストラリア人講師マーク先生が、全てのレッスンを担当します。実戦で使える生きた英語を身につけるためには、ネイティブと実際に会話をしながら学んでいくのが一番！英会話講師経験が豊富なマーク先生と一緒に、楽しく自然な英語を学んでいくことができます。",
      image: getImage(data.about1),
    },
    {
      title: "Task-Based Learning (TBL) による\n効果的な学習アプローチ",
      desc: "TBLとは、実用的なテーマや課題（タスク）に取り組むことでコミュニケーション能力の習得を目指していく外国語の学習方法です。文法やボキャブラリーも大事ですが、それ以上にタスクを遂行することを大事にすることでより効果的に英語学習を促進していきます。",
      image: getImage(data.about2),
    },
    {
      title: "あらゆるシチュエーションの\n英会話に自信をつける",
      desc: "ビジネス英会話、日常会話、受験準備などさまざまなシチュエーションに応じた英会話が自信を持ってできるように学習を進めていきます。子供から大人まで一人一人の英語力を伸ばすサポートをします。",
      image: getImage(data.about3),
    },
    {
      title: "レベルに合わせた\nオリジナルのカリキュラムや教材",
      desc: "レッスンのレベルに応じて、全てマーク先生がオリジナルでカリキュラムを作成します。英語の初心者から帰国子女の上級者まで、目的の英語レベルまで到達できるように、より適切な教材や方法を選択してレッスンを組み立てていきます。",
      image: getImage(data.about4),
    },
  ]
  return (
    <div className="mt-16 text-center">
      <div className="m-auto mx-[calc(50%_-_50vw)] mt-16">
        {items.map((item, index) => {
          return (
            <div
              key={item.title}
              className={`md:flex text-center ${
                index % 2 === 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="flex items-center my-5 md:my-0 md:w-1/2">
                <AnimatedOnScroll
                  animationIn="animate__rubberBand"
                  className="w-40 mx-auto"
                >
                  <GatsbyImage
                    image={item.image}
                    alt={item.title}
                    className="w-40 mx-auto"
                  />
                </AnimatedOnScroll>
              </div>
              <div className="py-10 md:py-20 md:w-1/2 bg-LightYellow">
                <div className="max-w-xl px-5 m-auto">
                  <p className="text-xl whitespace-pre-wrap font-ZenMaruGathic">
                    {item.title}
                  </p>
                  <div className="mt-5">{item.desc}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Feature
