import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import HeadlineM from "../HeadlineM"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

const Profile = () => {
  return (
    <div className="mt-16">
      <HeadlineM title="講師紹介" />
      <div className="grid-cols-5 mt-8 md:grid">
        <div className="col-span-2 mx-auto text-center">
          <AnimatedOnScroll delay={1000} animationIn="animate__pulse">
            <div className="hidden md:block">
              <StaticImage
                src="../../images/about/marc-full.png"
                alt="Teacher Marc"
                height={350}
              />
            </div>
            <div className="md:hidden">
              <StaticImage
                src="../../images/about/marc-full.png"
                alt="Teacher Marc"
                height={300}
              />
            </div>
          </AnimatedOnScroll>
        </div>
        <div className="col-span-3 my-auto">
          <p className="mt-5 text-2xl text-center md:text-left">
            マーク・ラボワピエール
          </p>
          <div className="flex items-center justify-center md:justify-start">
            <StaticImage
              src="../../images/about/aus-flag.png"
              alt="Australia Flag"
              className="h-fit"
            />
            <p className="ml-2 text-2xl">Marc Lavoipierre</p>
          </div>
          <div className="mt-5">
            <p>
              オーストラリア・ニューサウスウェールズ州出身、群馬県みなかみ町在住。
            </p>
            <p className="mt-5">
              2016年に大手英会話スクールの講師として来日。子どもから大人まで多くの人の英語学習をサポートしてきた。2021年、英会話講師・翻訳家として独立。オンラインレッスンをはじめ、学童施設やプライベートレッスンなど地域でも活動の幅を広げている。趣味は映画、読書、ゲーム、音楽（ロック）など多岐にわたる。
            </p>
          </div>
        </div>
      </div>
      <div className="relative mt-10 md:mt-8">
        <div className="absolute -top-4 -left-4">
          <StaticImage
            src="../../images/about/quote-left.png"
            alt="quote"
            width={30}
          />
        </div>
        <div className="p-5 bg-LightYellow">
          <p>
            Hello, I’m Marc, and I’m looking forward to getting to know you! I
            am really passionate about helping people from different cultures
            connect, and I think learning to speak English with confidence is
            very important for this. I want to help all of you to have fun
            communicating in English! See you soon!
          </p>
          <p className="mt-5">
            こんにちは、マークと申します。
            文化が違う人と繋がることはとても大事だと思っています。皆さんにも英語を通じて様々な人と繋がっていっていただくため、皆さんの英会話の勉強を精一杯サポートいたします！そしてなにより、楽しみながらコミュニケーションを取ることが一番大事だと思います。一緒に楽しく英語を話しましょう！
          </p>
        </div>
        <div className="absolute -bottom-4 -right-4">
          <StaticImage
            src="../../images/about/quote-right.png"
            alt="quote"
            width={30}
          />
        </div>
      </div>
    </div>
  )
}

export default Profile
